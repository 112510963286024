<template>
    <v-app>
        <Navbar :isMobile="$store.getters.isMobile" :categories="categories" :productsInCart="productsInCart" />

        <v-container fluid class="top">
            <v-row>
                <v-col cols="12" class="top-banner">
                    <v-img :src="storeData.topCase" class="img"></v-img>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="info-section">
            <v-row class="justify-center align-center">
                <v-col cols="12" sm="auto" class="info-col">
                    <v-icon class="icon">mdi-truck</v-icon>
                    <div class="info-text">
                        <span>ENTREGAMOS EM TODO O PAÍS</span>
                    </div>
                </v-col>
                <v-divider vertical class="mx-4 d-none d-sm-block"></v-divider>
                <v-col cols="12" sm="auto" class="info-col">
                    <v-icon class="icon">mdi-credit-card</v-icon>
                    <div class="info-text">
                        <span>PAGUE COMO QUISER</span>
                        <p>Cartões de crédito ou pix</p>
                    </div>
                </v-col>
                <v-divider vertical class="mx-4 d-none d-sm-block"></v-divider>
                <v-col cols="12" sm="auto" class="info-col">
                    <v-icon class="icon">mdi-shield</v-icon>
                    <div class="info-text">
                        <span>COMPRE COM SEGURANÇA</span>
                        <p>Seus dados sempre protegidos</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid class="image-section">
            <v-row justify="center">
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center">
                    <div class="image-card">
                        <v-img
                            src="https://acdn.mitiendanube.com/stores/002/952/786/themes/new_linkedman/2-img-1336767179-1714081934-4f3828fb313a03a0fabbc4d9e7e99bb71714081935-480-0.webp?1867500635"
                            class="image-item" @click="navigateTo('jaquetas')">
                        </v-img>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="d-flex justify-center">
                    <div class="image-card">
                        <v-img
                            src="https://acdn.mitiendanube.com/stores/002/952/786/themes/new_linkedman/2-img-1284554765-1714082506-db476276b5f8f9c4f1c0b9f5880b30711714082506-640-0.webp?1867500635"
                            class="image-item" @click="navigateTo('calcados')">
                        </v-img>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="body">
            <FourProducts title="Novidades" :isMobile="$store.getters.isMobile" :products="newProducts" />
        </v-container>

        <Footer :isMobile="$store.getters.isMobile" />

        <Loading v-if="loading" />
    </v-app>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'StorePage',
    metaInfo() {
        return {
            title: `${this.storeData.name}`
        }
    },
    components: {
        Navbar,
        FourProducts,
        Footer,
        Loading
    },
    data() {
        return {
            loading: true,
            productsInCart: 0,
            storeData: {
                banners: []
            },
            categories: [],
            newProducts: [],
        }
    },
    computed: {
        storeSlug() {
            return this.$store.getters.getStoreData.slug;
        }
    },
    async created() {
        this.loading = true
        this.verifyQntCart()
        await this.loadStoreData()
        this.loading = false
    },
    methods: {
        verifyQntCart() {
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
       
        async loadStoreData() {
            try {
                this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
                this.storeData.banners = [this.storeData.topCase, this.storeData.topCase, this.storeData.topCase]
                await this.getCategories()
                await this.getProducts()
            } catch (error) {
                console.error(error)
                this.$toast.error('Falha ao carregar dados da loja')
            }
        },
        async getCategories() {
            try {
                let response = await api.get('categories')
                this.categories = response.data
            } catch (error) {
                console.error(error)
                this.$toast.error('Falha ao carregar categorias')
            }
        },
        async getProducts() {
            try {
                let response = await api.post(`store/${this.storeData.id}/products`, { limit: 24 })
                this.newProducts = response.data
            } catch (error) {
                console.error(error)
                this.$toast.error('Falha ao carregar produtos')
            }
        },
        navigateTo(category) {
            this.$router.push(this.$route.params.storeSlug === undefined ? `category/${category}` : `/${this.$route.params.storeSlug}/category/${category}`)
        }
    }
}
</script>

<style scoped>
* {
    font-family: "Exo", sans-serif;
}

::placeholder {
    color: #00000066;
    opacity: 1;
}

.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}

.top-banner {
    padding: 0;
}

.banner-carousel {
    height: 620px;
}

.banner-carousel .v-carousel-item,
.banner-carousel .img {
    height: 100%;
    object-fit: cover;
}

.info-section {
    text-align: center;
    margin-top: 40px;
    padding: 20px 0;
}

.info-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}

.icon {
    font-size: 30px;
    margin-right: 10px;
}

.info-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info-col span {
    font-size: 16px;
    font-weight: bold;
}

.info-col p {
    font-size: 12px;
    color: #666;
    margin: 0;
}

.image-section {
    padding: 20px 0;
}

.image-card {
    overflow: hidden;
    border-radius: 8px;
}

.image-item {
    position: relative;
    height: 350px;
    object-fit: cover;
    margin-bottom: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.image-item:hover {
    transform: scale(1.1);
}

@media (min-width: 960px) {
    .image-item {
        height: 500px;
    }
}
</style>
