<template>
    <div>
        <v-container class="navbar" v-if="!isMobile">
            <v-row>
                <v-col cols="2" class="navbar-logo">
                    <img :src="storeData.logo" alt=""
                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)">
                </v-col>
                <v-col cols="8" class="navbar-search">
                    <!-- <input type="text" class="input-default" placeholder="Pesquisar por produtos..."> -->
                </v-col>
                <v-col cols="2" class="navbar-icons">
                    <v-badge :content="productsInCart" :value="productsInCart" color="black" overlap>
                        <img src="../assets/icons/cart.svg" alt=""
                            @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)">
                    </v-badge>
                    <!-- <img src="../assets/icons/user-roud.svg" alt=""> -->
                </v-col>
            </v-row>
            <v-row class="categories">
                <v-col>
                    <ul>
                        <li v-for="c in categories" :key="c.id">
                            <v-menu open-on-hover bottom offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn text v-bind="attrs" v-on="on"
                                        @click="$router.push($route.params.storeSlug == undefined ? `/category/${c.handle}` : `/${$route.params.storeSlug}/category/${c.handle}`)">
                                        {{ c.name }}
                                    </v-btn>
                                </template>

                                <v-list v-if="c.subcategories.length != 0">
                                    <v-list-item v-for="s in c.subcategories" :key="s.id" style="cursor: pointer;">

                                        <v-list-item-title
                                            @click="$router.push($route.params.storeSlug == undefined ? `/category/${s.handle}` : `/${$route.params.storeSlug}/category/${s.handle}`)"
                                            style="font-size: 10pt;">
                                            {{ s.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </li>
                    </ul>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="navbar-mobile" v-else>
            <v-row>
                <v-col cols="2" class="navbar-menu-icon" @click.stop="drawer = !drawer">
                    <img src="@/assets/icons/menu.svg" alt="">
                </v-col>
                <v-col cols="6" class="navbar-logo">
                    <img :src="storeData.logo" alt=""
                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)">
                </v-col>
                <v-col cols="4" class="navbar-icons">
                    <!-- <img src="../assets/icons/user-roud.svg" alt=""> -->
                    <v-badge :content="productsInCart" :value="productsInCart" color="black" overlap>
                        <img src="../assets/icons/cart.svg" alt=""
                            @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)">
                    </v-badge>
                    <!-- <img src="../assets/icons/search.svg" alt=""> -->
                </v-col>
            </v-row>
            <v-navigation-drawer v-model="drawer" absolute temporary>
                <v-list-item>
                    <v-img :src="storeData.logo"></v-img>
                </v-list-item>

                <v-divider></v-divider>
                <v-container style="height: 100vh; overflow: auto; padding-bottom: 300px;">
                    <v-row>
                        <v-col>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button style="width: 100%; text-align: left !important;"
                                        @click="$router.push($route.params.storeSlug == undefined ? `/` : `/${$route.params.storeSlug}`)"
                                        v-bind="attrs" v-on="on">
                                        INICIO
                                    </button>
                                </template>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row v-for="c in categories" :key="c.id">
                        <v-col>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <button style="width: 100%; text-align: left !important;" v-bind="attrs" v-on="on">
                                        {{ c.name }}
                                    </button>
                                </template>
                                <v-list>
                                    <v-list-item style="cursor: pointer;">
                                        <v-list-item-title
                                            @click="$router.push($route.params.storeSlug == undefined ? `/category/${c.handle}` : `/${$route.params.storeSlug}/category/${c.handle}`)"
                                            style="font-size: 10pt;">
                                            {{ c.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-for="s in c.subcategories" :key="s.id" style="cursor: pointer;">
                                        <v-list-item-title
                                            @click="$router.push($route.params.storeSlug == undefined ? `/category/${s.handle}` : `/${$route.params.storeSlug}/category/${s.handle}`)"
                                            style="font-size: 10pt;">
                                            {{ s.name }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-navigation-drawer>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    props: {
        isMobile: Boolean,
        productsInCart: Number,
        categories: Array
    },
    data() {
        return {
            storeData: {},
            drawer: null,
        }



    },
    async mounted() {
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
    }
}
</script>

<style scoped>
.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}

.categories ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    /* Permite que as categorias quebrem linha em telas menores */
}

.categories ul li {
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
}

@media (max-width: 1200px) {
    .categories ul li {
        font-size: 12px;
        /* Ajuste do tamanho da fonte para telas médias */
        margin-right: 4px;
    }
}

@media (max-width: 992px) {
    .categories ul li {
        font-size: 11px;
        /* Ajuste do tamanho da fonte para telas pequenas */
        margin-right: 3px;
    }
}

@media (max-width: 768px) {
    .categories ul li {
        font-size: 10px;
        /* Ajuste do tamanho da fonte para telas muito pequenas */
        margin-right: 2px;
    }
}

@media (max-width: 576px) {
    .categories ul li {
        font-size: 9px;
        /* Ajuste do tamanho da fonte para telas muito pequenas */
        margin-right: 1px;
    }
}

@media (max-width: 480px) {
    .categories ul li {
        font-size: 8px;
        /* Ajuste do tamanho da fonte para telas muito pequenas */
        margin-right: 1px;
    }
}

/* NAVBAR */
.navbar {
    padding: 20px;
}

.navbar .navbar-logo img {
    width: 100%;
    cursor: pointer;
}

.navbar .navbar-icons img {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
}

.navbar .categories {}

/* NAVBAR - MOBILE */
.navbar-mobile {
    padding: 20px;
}

.navbar-mobile .navbar-logo img {
    margin-top: 4px;
    width: 100%;
    cursor: pointer;
}

.navbar-mobile .navbar-icons img {
    margin-top: 14px;
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;
    text-align: right;
    float: right;
}

.navbar-mobile .navbar-menu-icon img {
    display: block;
    margin: 0 auto;
    margin-top: 16px;
    width: 60%;
    cursor: pointer;
}
</style>
