<template>
    <v-container fluid class="footer">
        <v-row>
            <v-col cols="12">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3" sm="6">
                            <v-row>
                                <v-col cols="12" class="logo">
                                    <img :src="storeData.logo" alt="">
                                </v-col>
                                <v-col cols="12" class="text">
                                    <p>
                                        Temos roupas que combinam com o seu estilo e das quais você se orgulha de usar.
                                    </p>
                                </v-col>
                                <v-col cols="12" class="social">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col v-if="storeData.twitterLink">
                                                <a :href="storeData.twitterLink ? storeData.twitterLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="blue" class="social-icon">mdi-twitter</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.facebookLink">
                                                <a :href="storeData.facebookLink ? storeData.facebookLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="blue darken-2"
                                                        class="social-icon">mdi-facebook</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.instagramLink">
                                                <a :href="storeData.instagramLink ? storeData.instagramLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="purple" class="social-icon">mdi-instagram</v-icon>
                                                </a>
                                            </v-col>
                                            <v-col v-if="storeData.youtubeLink">
                                                <a :href="storeData.youtubeLink ? storeData.youtubeLink : '#'"
                                                    target="_blank">
                                                    <v-icon color="red" class="social-icon">mdi-youtube</v-icon>
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row class="copy">
                        <v-col cols="12" md="4" class="text">
                            <p>© 2021 Beeasy. Todos os direitos reservados.<br><small>Desenvolvido por <a href=""
                                        target="_blank"
                                        style="text-decoration: none;color:#fff301;"><strong>Beeasy</strong></a></small>
                            </p>
                        </v-col>
                        <v-col cols="12" md="4" v-if="!isMobile"></v-col>
                        <v-col class="payments">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="2">
                                        <img src="../assets/icons/payments/visa.svg" alt="">
                                    </v-col>
                                    <v-col cols="2">
                                        <img src="../assets/icons/payments/master.svg" alt="">
                                    </v-col>
                                    <v-col cols="2">
                                        <img src="../assets/icons/payments/paypal.svg" alt="">
                                    </v-col>
                                    <v-col cols="2">
                                        <img src="../assets/icons/payments/applepay.svg" alt="">
                                    </v-col>
                                    <v-col cols="2">
                                        <img src="../assets/icons/payments/googlepay.svg" alt="">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Footer',
    props: {
        isMobile: Boolean
    },
    data() {
        return {
            storeData: {}
        }
    },
    async mounted() {
        this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
    }
}
</script>

<style scoped>
.footer {
    padding-top: 150px;
    background-color: #1C1A1D;
    color: #fff;
}

.footer .logo img {
    width: 50%;
}

.footer .text {
    font-family: Exo;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left
}

.social-icon {
    font-size: 30px;
    cursor: pointer;
}

.copy .text {
    padding-top: 40px;
}
</style>