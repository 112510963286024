<template>
    <v-app>
        <!-- <Notice :isMobile="$store.getters.isMobile" :message="message"/> -->

        <Navbar :isMobile="$store.getters.isMobile" :categories="categories" :productsInCart="productsInCart"/>

        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <SlideProduct :images="product.product.images" />
                </v-col>
                <v-col>
                    <v-row>
                        <v-col>
                            <h1>{{ product.product.name }}</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-rating
                                :v-model="5"
                                color="amber"
                                background-color="grey lighten-1"
                                dense
                                half-increments
                                readonly
                            ></v-rating>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>{{ parseFloat(product.sale_price).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h2>
                        </v-col>
                    </v-row>
                    <v-row v-if="product.product.variants.length > 1">
                        <v-col cols="12" md="12">
                            Tamanho: <strong>{{ variantSelected.value }}</strong> 
                        </v-col>
                        <v-col>
                            <v-btn
                                class="mx-2"
                                small
                                rounded
                                v-for="(variant, index) in product.product.variants"
                                :key="index"
                                :disabled="variant.stock == 0 ? true : false"
                                :color="variantSelected.value == variant.value ? 'black' : 'white lighten-1'"
                                :dark="variantSelected.value == variant.value ?  true : false"
                                @click="variantSelected = variant"
                            >
                             {{ variant.value }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-container>
                                <v-row class="input-qnt">
                                    <v-col class="remove" @click="qnt <= 1 ? qnt = 1 : qnt--" style="padding: 4px;">
                                        <img src="../assets/icons/minus.svg" alt="">
                                    </v-col>
                                    <v-col class="qnt" style="padding: 4px;">
                                        {{ qnt }}
                                    </v-col>
                                    <v-col class="add" @click="qnt++" style="padding: 4px;">
                                        <img src="../assets/icons/plus.svg" alt="">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="6">
                            <button class="btn-default" @click="addToCart" v-if="!hasInCart">Adicionar ao carrinho</button>
                            <button class="btn-default" @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)" v-if="hasInCart">Ver carrinho</button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <!-- <CostumersRate title="Avaliações" :rates="rates" :isMobile="$store.getters.isMobile"/> -->


        <v-container class="body">
            <FourProducts title="Você pode gostar" :isMobile="$store.getters.isMobile" :products="products.slice(-4)" />
        </v-container>
    
        <Footer :isMobile="$store.getters.isMobile" />
        <Loading v-if="loading"/>
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import SlideProduct from '@/components/SlideProduct.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CostumersRate from '@/components/CostumersRate.vue'

import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'DetailsPage',
    metaInfo() {
        return {
        title: `${this.storeData.name} - Detalhes`
        }
    },
    components: {
        Notice,
        Navbar,
        FourProducts,
        Footer,
        SlideProduct,
        FontAwesomeIcon,
        CostumersRate,
        Loading
    },
    data(){
        return {
            loading: true,
            storeData: '',
            message: "Cadastre-se e ganhe 20% de desconto no seu primeiro pedido. Inscreva-se agora",
            title: 'Site Loja',
            categories: [],
            productsInCart: 0,
            hasInCart: false,
            topBanner: require(`@/assets/banner-topo.jpg`),
            variantSelected: {},
            qnt: 1,
            product: {
            },
            products: [
            ],
            rates: [
                {
                    id: 1,
                    rate: 5,
                    name: 'Cliente 1',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 2,
                    rate: 4,
                    name: 'Cliente 2',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 3,
                    rate: 3,
                    name: 'Cliente 3',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 4,
                    rate: 2,
                    name: 'Cliente 4',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 5,
                    rate: 1,
                    name: 'Cliente 5',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                }
            ]
        }
    },
    async created(){
        await this.init()
    },
    async mounted(){
        
    },
    watch: {
        async '$route' (to, from) {
            await this.init()
            await this.scrollToTop();
        }
    },
    methods: {
        async scrollToTop() {
            window.scrollTo(0,0);
        },
        async init(){
            window.fbq('track', 'ViewContent', {
                content_ids: [this.$route.params.id],
                content_type: 'product',
                value: this.product.sale_price,
                currency: 'BRL'
            });
            this.loading = true;
            this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
            await this.getProduct()
            await this.getProducts()
            await this.getCategories()
            await this.scrollToTop();
            await this.verifyCart();
            await this.verifyQntCart();
            this.loading = false;
        },
        async verifyQntCart(){
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
        async verifyCart(){
            let cart = JSON.parse(localStorage.getItem('cart'))
            if(cart){
                let p = cart.find(p => p.product.id == this.product.product.id)
                if(p != undefined){
                    this.hasInCart = true
                }else{
                    this.hasInCart = false
                }
            }else{
                this.hasInCart = false
            }
        },
        async addToCart(){
            window.fbq('track', 'AddToCart', {
                content_ids: [this.product.product.id],
                content_type: 'product',
                value: this.product.sale_price,
                currency: 'BRL'
            });
            let cart = JSON.parse(localStorage.getItem('cart'))
            if(this.qnt > this.variantSelected.stock){
                alert('Quantidade maior que o estoque')
                return
            }
            this.product.qnt = this.qnt
            this.product.variantSelected = this.variantSelected
            if(cart){
                cart.push(this.product)
                localStorage.setItem('cart', JSON.stringify(cart))
            }else{
                localStorage.setItem('cart', JSON.stringify([this.product]))
            }
            await this.verifyCart();
            await this.verifyQntCart();
        },
        async getCategories(){
            try {
                let response = await api.get(`categories`)
                this.categories = response.data
            } catch (error) {
                console.log(error)
            }
        },
        async getProduct(){
            try {
                let response = await api.get(`store/${this.storeData.id}/products/${this.$route.params.id}`)
                this.product = response.data 
                this.product.product.variants = JSON.parse(this.product.product.variants)
                this.variantSelected = this.product.product.variants[0]
            } catch (error) {
                console.log(error)
            }
        },
        async getProducts(){
            try {
                let response = await api.post(`store/${this.storeData.id}/products`, {limit: 24, category: JSON.parse(this.product.product.categories)[0]})
                this.products = response.data 
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>
    *{
        font-family: "Exo", sans-serif;
    }
    ::placeholder {
        color: #00000066;
        opacity: 1; /* Firefox */
    }

    h2{
        font-size: 2.5rem;
        font-weight: 700;
    }

    .btn-default{
        width: 100%;
        background-color: #000;
        color: #fff;
        border-radius: 62px;
        padding: 10px;
    }


    .input-qnt{
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }
    .input-qnt .remove img, .input-qnt .add img{
        width: 20px;
        cursor: pointer;
    }

    .input-qnt .qnt{
        text-align: center;
        font-size: 1.5rem;
    }

    .input-qnt .add img{
        float: right;
        margin-top: 8px;
        margin-right: 10px;
    }
    .input-qnt .remove img{
        float: left;
        margin-top: 8px;
        margin-left: 10px;
    }
    /*  */
    /*  */
    /*  */

    .input-default{
        width: 100%;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }

    .top-banner{
        padding: 0;
    }
    .top-banner img{
        width: 100%;
    }
    .top .top-separator{
        margin-bottom: 25vh;
    }

    .top .top-separator img.banner1{
        position: absolute;
        margin-top: -20vh;
        width: 100%;

    }
    .top .top-separator img.banner2{
        position: absolute;
        margin-top: -50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
        .top .top-separator img.banner1{
            position: absolute;
            margin-top: -15vh;
            width: 100%;
        }
        .top .top-separator img.banner2{
            position: absolute;
            margin-top: -43vh;
            width: 100%;
        }
    }


    /* TOP SEPARATOR MOBILE */
    .top .top-separator-mobile{
        margin-bottom: 10vh;
    }
    .top .top-separator-mobile img.banner1{
        position: absolute;
        top: 9rem;
        width: 100%;

    }
    .top .top-separator-mobile img.banner2{
        position: absolute;
        top: 21rem;
        width: 100%;
    }

</style>