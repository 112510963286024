<template>
    <div class="fill">
        <div class="loading">
            <!-- <img src="@/assets/loading.gif" alt=""> -->
            <img src="@/assets/coroa.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
}
</script>

<style scoped>
    .fill{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
    }
    .loading img{
        width: 20%;
        display: block;
        margin: 0 auto;
        margin-top: 25vh;
    }

    .loading {
	animation: pulse 1.5s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.90);
	}
    

	70% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(0.90);
	}
}
</style>